<template>
  <section class="min-w1600">
     <div class="searchwrap">
        <div class="searchBar">
           <div :class="'searchbar-content'">
             <div class="title"><span>{{ $t('common.searchOp') }}</span></div>
             <div>
               <input type="text" class="mr-5" v-model="reqData.memId" />
               <button class="btn-search" type="button" @click="pageSeach">
                <i class="fas fa-search"></i>
               </button>
             </div>
           </div>
        </div>
        <memo></memo>
     </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
          <button type="button" class="btn-layout btn-grey" title="새로고침" @click="setTableData(reqData.page)">
            <i class="fas fa-redo"></i>
          </button>
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody>
            <template v-if="cashList.length > 0">
              <tr v-for="(item, idx) in cashList" :key="idx">
                <td>
                  <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
                </td>
                <td>
                  <span class="fc-nick">{{ item.memNick }}</span>
                </td>
                <td class="roboto">{{ item.cashAmt }}</td>
                <td>
                  <span class="roboto">{{ item.totalCashInAmt }}</span>
                </td>
                <!-- todo: 입금 기능 추가 -->
                <!-- <td class="bl-none">
                  <button type="button" class="btn-money cashIn">{{ $t('searchArea.payment') }}</button>
                </td> -->
                <td>
                  <span class="roboto">{{ item.totalCashOutAmt }}</span>
                </td>
                <!-- todo: 출금 기능 추가 -->
                <!-- <td class="bl-none">
                  <button type="button" class="btn-money cashOut">{{ $t('searchArea.retrieval') }}</button>
                </td> -->
                <td class="roboto">{{ item.progBetAmt }}</td>
                <td class="roboto">{{ item.totalWlAmt }}</td>
                <td width="5%">
                  <!-- todo: 출금정지 기능 추가 -->
                  <button v-if="item.outAmtYn==='N'" type="button" class="btn-layout btn-red mr-0" @click="memCashOutToggle('Y',item.memId)">{{ $t('common.restore') }}</button>
                  <button v-else type="button" class="btn-layout btn-blue mr-0" @click="memCashOutToggle('N',item.memId)">{{ $t('button.outStop') }}</button>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="8">{{ $t('txt.noData') }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="cashList.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import TableHead from "@/components/main/table/Head.vue"
import SearchFilter from "@/components/common/SearchFilter"
import Pagination from "@/components/common/Pagination"
import { memberCashList, memberCashStop } from "@/api/member.js"
import { replaceDateT, numberWithCommas } from "@/libs/utils.js"
import Memo from '@/components/common/memo'

export default {
  name: "UserCashList",
  components: {
    TableHead,
    SearchFilter,
    Pagination,
    Memo
  },
  data() {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ["memId", "memNick", "cashAmt", "totalCashInAmt", "totalCashOutAmt", "progBetAmt", "totalWlAmt", "option"]
        // ,
        // addAttrs:[
        //   {
        //     target:"totalCashInAmt",
        //     attrs:{
        //       "col-span":2
        //     }
        //   },
        //   {
        //     target:"totalCashOutAmt",
        //     attrs:{
        //       "col-span":2
        //     }
        //   }
        // ]
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        count_per_page: 10,
        memId: ""
      },
      srchFiltersProp: {
        placeholder: "아이디 검색",
        selectOptions: ""
      },
      cashList: [],
      pageInfo: {}
    };
  },
  methods: {
    async memCashOutRollback(memId) {
      const reqData = {
        memId:memId,
        memStatus: "1",
        memo: "out",
        outAmtYn: "Y"
      };
      const res = await memberCashStop(reqData);
      if (res.resultCode === "0") {
        alert("출금정지 취소 완료");
      } else {
        alert("출금정지 취소 실패, 다시 시도해주세요.");
      }
    },
    async memCashOutToggle(yn,memId) {
      const reqData = {
        memId:memId,
        memStatus: "1",
        memo: "out",
        outAmtYn: yn
      };
      let text = "정지"
      if(yn==="Y"){
        text = "복구"
      }
      const res = await memberCashStop(reqData);
      if (res.resultCode === "0") {
        alert("출금" + text + "완료");
        this.setTableData(1);
      } else {
        alert("출금" + text + "변경 실패, 다시 시도해주세요.");
      }
    },
    pageSeach() {
      this.setTableData(1);
    },
    async setTableData(pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum;
      }
      const data = this.reqData;
      const listRes = await memberCashList(data);
      console.log(listRes)
      const pageInfo = listRes.data.pageInfo ? listRes.data.pageInfo : this.pageInfo
      this.pageInfo = pageInfo;
      const cashList = listRes.data.list;
      cashList.forEach(items => {
        for (const item in items) {
          if (item === "regDt" || item === "lastLoginDt") {
            items[item] = replaceDateT(items[item]);
          }
          if (!isNaN(Number(items[item]))) {
            items[item] = numberWithCommas(items[item]);
          }
        }
      });
      this.cashList = cashList;
    }
  },
  async created() {
    this.setTableData(1);
  }
};
</script>

<style scoped>
.active .btnPhoneNum {
  display: none;
}
</style>
